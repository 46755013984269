import React from "react";

import ReferenciasCubiertas from "src/components/referencias/Cubiertas";
import { LayoutReferencia } from "src/layout/LayoutReferencia";

const ReferenciasCubiertasPage = (props) => (
  <LayoutReferencia
    {...{
      pageTitleJSX: (
        <span>
          <span className="mainTitle colored thermoActive">
            Protección y Ahorro económico:
          </span>
          <span className="secondaryTitle">
            Referencias en España de ClimateCoating para Cubiertas y Tejados
          </span>
        </span>
      ),
      pageTitle: "Referencias de ClimateCoating para Cubiertas/Tejados",
      pageName: "ThermoActive",
      pageDescription:
        "Referencias en España de ClimateCoating para Cubiertas y Tejados",
      activePage: "cubiertas",
    }}
  >
    <ReferenciasCubiertas
      {...{
        xs: 1,
        sm: 2,
        xl: 4,
        xxl: 4,
      }}
    />
  </LayoutReferencia>
);

export default ReferenciasCubiertasPage;
