import React from "react";

import { Avatar, Col, Card, List, Space } from "antd";
import { Link } from "gatsby";
import "src/components/productCategories/style.scss";

import { CheckOutlined, PlusCircleOutlined } from "@ant-design/icons";

import revenga from "src/components/referencias/images/cubiertas/climateCoating-thermoActive-cubierta-oficinas-centrales-grupo-revenga.jpg";
import tejadoNorteMadrid from "src/components/referencias/images/cubiertas/climateCoating-thermoActive-tejado-vivienda-norte-madrid.jpg";
import faunia from "src/components/referencias/images/cubiertas/climateCoating-thermoActive-cubierta-faunia-madrid.jpg";

import lcFirst from "utils/lcFirst";

import thermoActiveAvatarImg from "src/components/products/images/thermoActiveAvatar.jpg";

const { Meta } = Card;

export const ProductCategoryExteriorComponent = ({}) => {
  const listData = [
    {
      title: "ThermoActive - Aplicación en cubierta sobre tela asfáltica",
      list: ["Ahorro Energético", "Protección de la edificación"],
      imgSrc: revenga,
      productName: "ThermoActive",
      avatar: thermoActiveAvatarImg,
      productTitle: "ThermoActive",
      description:
        "Cubierta de las oficinas centrales del Grupo Reventa afueras de Madrid",
      content:
        "Para preservar las caracteríticas impermeabilizantes de la tela asfáltica y generar ahorro energético.",
      linkToReferencia: "/referencias-cubiertas/cubierta-sede-grupo-revenga",
    },
    {
      title:
        "ThermoActive - Aplicación en tejado degradado en vivienda del Norte de Madrid",
      list: [
        "Ahorro Energético",
        "Mejora Estética",
        "Protección de la edificación",
      ],
      imgSrc: tejadoNorteMadrid,
      productName: "ThermoActive",
      avatar: thermoActiveAvatarImg,
      productTitle: "ThermoActive",
      description:
        "Revestimiento de tejado degradado en vivienda unifamiliar del Norte de Madrid",
      content: "",
    },
    {
      title: "ThermoActive - Aplicación en cubierta- Faunia Madrid",
      list: ["Ahorro Energético", "Flexibilidad", "Producto Ecológico"],
      imgSrc: faunia,
      productName: "ThermoActive",
      avatar: thermoActiveAvatarImg,
      productTitle: "ThermoActive",
      description:
        "Revestimiento de carpa flexible y cubierta metalica en Faunia Madrid",
      content:
        "Al requisito del ahorro energético se sumó la necesidad de flexbilidad para revestir la carpa y la obligatoriedad de no contar con disolventes",
      linkToReferencia: "/referencias-cubiertas/carpa-cubierta-faunia",
    },
  ];

  const IconText = ({ icon, text, linkToReferencia }) => (
    <Space>
      {linkToReferencia ? (
        <Link className="thermoActive bold" to={linkToReferencia}>
          {React.createElement(icon)}
          {"  "}
          {text}
        </Link>
      ) : (
        <>
          {React.createElement(icon)}
          {text}
        </>
      )}
    </Space>
  );

  return (
    <div className="ccContent referencias">
      <List
        {...{
          itemLayout: "vertical",
          size: "large",
          pagination: {
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 5,
          },
          dataSource: listData,
          footer: (
            <div>
              Referencias de proyectos que han usado{" "}
              <b>ClimateCoating en España</b>
            </div>
          ),

          renderItem: ({
            title,
            list,
            avatar,
            productName,
            description,
            content,
            imgSrc,
            linkToReferencia,
          }) => (
            <List.Item
              key={title}
              actions={list
                .map((feature, key) => (
                  <IconText
                    {...{
                      icon: CheckOutlined,
                      text: feature,
                      key,
                    }}
                  />
                ))
                .concat(
                  linkToReferencia ? (
                    <IconText
                      {...{
                        icon: PlusCircleOutlined,
                        text: "Más Info",
                        key: "masInfo",
                        linkToReferencia,
                      }}
                    />
                  ) : (
                    []
                  )
                )}
              extra={<img width={272} alt="logo" src={imgSrc} />}
            >
              <List.Item.Meta
                avatar={<Avatar src={avatar} />}
                title={
                  <Link
                    to={`/productos/${productName.toLowerCase()}`}
                    className={lcFirst(productName)}
                  >
                    {title}
                  </Link>
                }
                description={description}
              />
              {content}
            </List.Item>
          ),
        }}
      />
    </div>
  );
};

export default ProductCategoryExteriorComponent;
